import { Button, Form, Input, Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import '../ExerciseContent.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { GetWord, randomGuid } from '../../../../../../../utils'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Service from '../../../../../../../Service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { ImgUpload } from '../../../../../../../utils/firebase';

const VoiceAdded = ({ selectedData, setIsReflesh, setisWordAdded }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const { lessonId, lessonRoadId } = useParams();

    useEffect(() => {
        if (selectedData && selectedData.type === "listening") {
            setImageUrl(selectedData.question);
        }
    }, [selectedData])

    const beforeUpload = (file) => {
        const isAudio = file.type.startsWith('audio/');
        if (!isAudio) {
            message.error('Lütfen sadece ses dosyası yükleyin!');
        }
        return isAudio;
    };

    const onFinish = useCallback((value) => {
        const service = new Service();
        value["question"] = imageUrl ? imageUrl : selectedData.word;
        value["question_language_id"] = parseInt(localStorage.getItem("selectedLang"));
        value["answer_language_id"] = parseInt(localStorage.getItem("selectedTranslateLang"));
        value["type"] = "listening";
        value["correnct_question"] = value.answer[0];
        if (selectedData) {
            value["exercise_id"] = selectedData.exercise_id;
            service.AdminService.UpdateExerciseQuestion(value, selectedData.id).then((res) => {
                if (!res.error && res.data) {
                    setIsReflesh((prev) => !prev);
                    setisWordAdded(false);
                    toast.success(GetWord("Added successfully."));
                } else {
                    toast.error(res.message);
                }
            })
        } else {
            service.AdminService.PostExercise({
                road_id: parseInt(lessonRoadId),
                title: "",
                id: parseInt(lessonId),
                type: "lesson",
            }).then((res2) => {
                if (!res2.error && res2.data) {
                    value["exercise_id"] = res2.data.id;
                    service.AdminService.PostExerciseQuestion(value).then((res) => {
                        if (!res.error && res.data) {
                            setIsReflesh((prev) => !prev);
                            setisWordAdded(false);
                            toast.success(GetWord("Added successfully."));
                        } else {
                            toast.error(res.message);
                        }
                    })
                } else {
                    toast.error(res2.message);
                }
            })
        }



    }, [imageUrl, lessonId, lessonRoadId, selectedData, setIsReflesh, setisWordAdded]);


    const handleChange = useCallback(async (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            if (!info.file.type.startsWith('audio/')) {
                message.error('Lütfen sadece ses dosyası yükleyin!');
                setLoading(false);
                return;
            }

            var responseUrl = await ImgUpload(
                info?.file?.originFileObj,
                "webapp/Voice/",
                randomGuid()
            );
            setImageUrl(responseUrl);
        }
    }, []);


    const customRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <div className='ExerciseContent-BodyArea-AddWords-Modal'>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                className='ExerciseContent-BodyArea-AddWords-Modal'
                initialValues={selectedData}
            >

                <Form.Item
                    label="Ses Dosyası Yükle"
                    rules={[
                        {
                            required: true,
                            message: GetWord("Please upload an audio file!"),
                        },
                    ]}
                    className='ExerciseContent-BodyArea-AddVoice-Modal'
                >
                    <Upload
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        onChange={handleChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: false }}
                    >
                        <Button icon={<UploadOutlined />}>{GetWord("Select File")}</Button>
                    </Upload>
                </Form.Item>


                {imageUrl && (
                    <div>
                        <audio controls>
                            <source src={imageUrl} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                )}



                <Form.Item name={["answer", 0]} label={<p className='ExerciseContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Answer True")}</p>} className='ExerciseContent-BodyArea-AddWords-Modal-WordsComment'>
                    <Input type='input' placeholder="Answer True" className='ExerciseContent-BodyArea-AddWords-Modal-RulesComment' />
                </Form.Item>

                <Form.Item name={["answer", 1]} label={<p className='ExerciseContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Answer False")}</p>} className='ExerciseContent-BodyArea-AddWords-Modal-WordsComment'>
                    <Input type='input' placeholder="Answer False" className='ExerciseContent-BodyArea-AddWords-Modal-RulesComment' />
                </Form.Item>

                <Form.Item name={["answer", 2]} label={<p className='ExerciseContent-BodyArea-AddWords-Modal-WordsComment-Title'>{GetWord("Answer False")}</p>} className='ExerciseContent-BodyArea-AddWords-Modal-WordsComment'>
                    <Input type='input' placeholder="Answer False" className='ExerciseContent-BodyArea-AddWords-Modal-RulesComment' />
                </Form.Item>



                <Form.Item className='ExerciseContent-BodyArea-AddWords-Modal-Button'>
                    <Button htmlType="submit" className='ExerciseContent-BodyArea-AddWords-Modal-Button-Style'>
                        <p className='ExerciseContent-BodyArea-AddWords-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save ExerciseContent-BodyArea-AddWords-Modal-Button-Text' /></p>
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default VoiceAdded;
