import { Button, Form, Input, Radio, Select, Space, Upload } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import '../DialogueContent/DialogueContent.scss'
import { GetWord, randomGuid } from '../../../../../utils';
import Service from '../../../../../Service';
import { toast } from 'react-toastify';
import { ImgUpload } from '../../../../../utils/firebase';
import { useParams } from 'react-router-dom';

const UnitAdd = ({ personelList, setIsModalOpen, setIsReflesh, selectedData }) => {

    const [value, setValue] = useState(1);
    const [imageUrl, setImageUrl] = useState(null);
    const { id } = useParams();

    const onFinish = useCallback((values) => {
        const service = new Service();
        values['icon_url'] = imageUrl ? imageUrl : selectedData?.icon_url;
        values['dialogs_id'] = parseInt(id);
        if (selectedData) {
            service.AdminService.UpdateDialogRoad(selectedData.id, values).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen(false);
                    setIsReflesh((prev) => !prev);
                }
                else {
                    toast.error(res.message);
                }
            })
        }
        else {
            service.AdminService.PostDialogsRoad(values).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen(false);
                    setIsReflesh((prev) => !prev);
                }
                else {
                    toast.error(res.message);
                }
            })
        }
    }, [id, imageUrl]);

    const handleChange = useCallback(async (info) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            var responseUrl = await ImgUpload(
                info?.file?.originFileObj,
                "webapp/",
                randomGuid()
            );
            setImageUrl(responseUrl);
        }
    }, []);

    useEffect(() => {
        if (selectedData)
            setImageUrl(selectedData.icon_url)
    }, [selectedData])
    return (
        <div className='DialogueContent-HeaderArea-AddDialogue-Form'>
            <Form
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
                initialValues={selectedData}
            >
                <div className='DialogueContent-AddDialogue'>
                    <Form.Item className='DialogueContent-AddDialogue-UnitName' name='name'>
                        <Input placeholder={GetWord("Dialog Name")} />
                    </Form.Item>
                    <Upload onChange={handleChange}
                        action="https://httpstat.us/200"
                        listType="picture"
                        maxCount={1}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                    width: '100%',
                                }}
                            />
                        ) : <Button className='DialogueContent-AddDialogue-PhotoUpload-Button-Style'>
                            <div className='DialogueContent-AddDialogue-PhotoUpload-Button'>
                                <span className='DialogueContent-AddDialogue-PhotoUpload-Button-Text'>{GetWord("Dialog Image")}</span>
                                <UploadOutlined />
                            </div></Button>

                        }

                    </Upload>

                    <div className='DialogueContent-AddDialogue-DialogueTranslate'>

                        <Form.List name={['texts', "messages"]}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'grid',
                                                marginBottom: 12,
                                                marginTop: 12,
                                            }}
                                            align="baseline"
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <p className='DialogueContent-AddDialogue-DialogueTranslate-Title'> <i className='icon-reorder' />{GetWord("Dialog")} {key}</p>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </div>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'text']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: GetWord("Missing dilaog"),
                                                    },
                                                ]}
                                            >
                                                <Input placeholder={GetWord("Enter a dialog")} className='DialogueContent-AddDialogue-DialogueTranslate-DialogueInput' />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'translate_text']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: GetWord("Missing Translation"),
                                                    },
                                                ]}
                                            >
                                                <Input placeholder={GetWord("Enter a translation")} />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'sender_id']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: GetWord("No Speaker Selected"),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder={GetWord("Speaker select")}
                                                    style={{
                                                        width: 120,
                                                    }}
                                                    options={
                                                        personelList
                                                    }
                                                />
                                            </Form.Item>

                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block >
                                            {GetWord("Add Dailog")} <PlusOutlined />
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                    </div>
                </div>
                <Form.Item>
                    <Button htmlType='submit' className='DialogueContent-AddDialogue-Button-Style'>
                        <p className='DialogueContent-AddDialogue-Button-Text'>{GetWord("Save")}<i className='icon-check_circle' style={{ fontSize: 18, marginLeft: 5 }} /></p>
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default UnitAdd