import React, { useCallback, useEffect, useState } from 'react'
import './RulesContent.scss'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../../utils';
import { Button, Input, Modal, Table } from 'antd';
import AddRules from './components/AddRules';
import CommentAdded from './components/CommentAdded';
import TrueExample from './components/TrueExample';
import { useDispatch, useSelector } from 'react-redux';
import { getLessonContent } from '../../../../../../stores/lessonContentdata';
import FalseExample from './components/FalseExample';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';


const RulesContent = () => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isFalseExample, setIsFalseExample] = useState(false);
    const [isTrueExample, setIsTrueExample] = useState(false);
    const [isCommentAdded, setIsCommentAdded] = useState(false);
    const [selectData, setselectData] = useState(null);
    const rules = useSelector(state => state.lessonContentdata.rules)
    const name = useSelector((state) => state.lessonContentdata.name)
    const { lessonRoadId } = useParams();
    const dispatch = useDispatch();
    const site = useSelector(state => state.site);
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value)
    });

    useEffect(() => {
        dispatch(getLessonContent(lessonRoadId))
    }, [dispatch, lessonRoadId, isUpdate])

    const List = [
        {
            title: GetWord(""),
            dataIndex: 'icon',
            render: (username, data) =>
                <i className='icon-reorder RulesContent-BodyArea-List-Icon' />,
        },

        {
            title: GetWord("Header"),
            dataIndex: 'title',
            render: (header, data) =>
                <p className='RulesContent-BodyArea-List-Name'>{header}</p>
        },
        {
            title: GetWord('Explanation'),
            dataIndex: 'description',
            render: (text, record) =>
                <Link className='RulesContent-BodyArea-List-Explanation' onClick={() => commentAdded(record)}>
                    <i className='icon-more_horiz' />
                    <p className=''>{text.length > 0 ? GetWord("DescriptionAdded") : "NoDescriptionAdded"}</p>
                </Link>

        },
        {
            title: GetWord('True Example'),
            dataIndex: 'sample_sentece',
            render: (text, record) =>
                <Link className='RulesContent-BodyArea-List-TrueExample' onClick={() => trueExample(record)}>
                    <i className='icon-more_horiz' />
                    <p className=''>{text.length > 0 ? GetWord("Example") : "NoExample"}</p>
                </Link>

        },
        {
            title: GetWord('False Example'),
            dataIndex: 'incorrect_sentence',
            render: (text, record) =>
                <Link className='RulesContent-BodyArea-List-FalseExample' onClick={() => falseExample(record)}>
                    <i className='icon-more_horiz' />
                    <p className=''>{text.length > 0 ? GetWord("Example") : "NoExample"}</p>
                </Link>

        },

        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='RulesContent-BodyArea-List-ButtonArea'>
                    <button className='RulesContent-BodyArea-List-ButtonArea-DeleteButton' onClick={() => onDelete(record)} >
                        <i className='icon-dangerous RulesContent-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='RulesContent-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='RulesContent-BodyArea-List-ButtonArea-EditButton' onClick={() => rulesAdd(record)}>
                        <i className='icon-edit RulesContent-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='RulesContent-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>
        },
    ];

    const rulesAdd = useCallback((value) => {
        setselectData(value);
        setIsModalOpen(true);
    }, []);
    const noCancel = () => {
        setIsModalOpen(false);
        setIsCommentAdded(false);
        setIsTrueExample(false);
        setIsFalseExample(false);
    };
    const commentAdded = useCallback((value) => {
        setselectData(value)
        setIsCommentAdded(true);
    }, []);

    const trueExample = useCallback((value) => {
        setselectData(value)
        setIsTrueExample(true);
    }, []);

    const onDelete = useCallback((value) => {
        setselectData(value)
        setIsDeleteModalOpen(true);
    }, []);

    const falseExample = useCallback((value) => {
        setselectData(value)
        setIsFalseExample(true);
    }, []);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className='RulesContent'>

            <div className='RulesContent-HeaderArea'>
                <div className='RulesContent-HeaderArea'>
                    <div className='RulesContent-HeaderArea-PageName'> <Link className="RulesContent-prevlink" onClick={handleGoBack}>{GetWord("Lessons")} - {site.unitName} - {name} - {GetWord("Rules")} </Link> </div>
                </div>
                <div className='RulesContent-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search RulesContent-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord(" Search for a rule")} />
                    <div className='RulesContent-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => dispatch(getLessonContent(lessonRoadId, search ? "search=" + search : ""))}
                            className='RulesContent-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='RulesContent-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt RulesContent-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
            </div>

            <>
                <Modal title="" destroyOnClose open={isCommentAdded} onCancel={noCancel} closable={false} className='RulesContent'
                    footer={null}>
                    <CommentAdded selectData={selectData} setIsCommentAdded={setIsCommentAdded} setIsUpdate={setIsUpdate} />
                </Modal>

                <Modal title="" destroyOnClose open={isTrueExample} onCancel={noCancel} closable={false} className='RulesContent'
                    footer={null}>
                    <TrueExample selectData={selectData} setIsTrueExample={setIsTrueExample} setIsUpdate={setIsUpdate} />
                </Modal>

                <Modal title="" destroyOnClose open={isFalseExample} onCancel={noCancel} closable={false} className='RulesContent'
                    footer={null}>
                    <FalseExample selectData={selectData} setIsFalseExample={setIsFalseExample} setIsUpdate={setIsUpdate} />
                </Modal>
            </>

            <div className='RulesContent-BodyArea'>
                <div className='dialogue-BodyArea-List'>
                    <Table
                        columns={List}
                        dataSource={rules}
                        scroll={{ x: 'fit-content' }}
                        pagination={{
                            pageSize: 5
                        }}
                    />
                    <div className='RulesContent-BodyArea-AddRules-Button'>
                        <Button className='RulesContent-BodyArea-AddRules-Button-Style' type="primary" onClick={() => rulesAdd(null)}>
                            <p className='RulesContent-BodyArea-AddRules-Button-Text'>{GetWord("Add Rule")}</p>
                            <div className='RulesContent-BodyArea-AddRules-Button-Icon' >
                                <i className='icon-add' />
                            </div>
                        </Button>

                        <>
                            <Modal title="" open={isModalOpen} destroyOnClose onCancel={noCancel} closable={false} className='RulesContent'
                                footer={null}>
                                <AddRules selectData={selectData} setIsModalOpen={setIsModalOpen} setIsUpdate={setIsUpdate} />
                            </Modal>
                        </>
                    </div>
                </div>
            </div>
            <DeleteModal setIsModalOpen={setIsDeleteModalOpen} isModalOpen={isDeleteModalOpen} url="lessons/roads/rules" setIsReflesh={setIsUpdate} seletecedId={selectData?.id} />
        </div>
    )
}

export default RulesContent