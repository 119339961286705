import React, { useCallback, useEffect, useState } from 'react'
import './Users.scss'
import { Button, Checkbox, Input, Modal, Table } from 'antd'
import { GetWord } from '../../../../utils';
import BlockSelected from './components/BlockSelected';
import LoadingPage from '../../../shared/LoadingPage/LoadingPage';
import Service from '../../../../Service';
import moment from 'moment';
import 'moment/locale/tr';
import BlockSelectedOk from './components/BlockSelectedOk';

const Users = () => {
    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [seletedData, setSelectedData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isYesBlockIt, setIsYesBlockIt] = useState(false);
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value)
    });

    const getUserList = useCallback((search) => {
        const service = new Service();
        service.AdminService.getUserList(search ? "search=" + search : "").then((res) => {
            if (!res.error && res.data) {
                setLoading(true);
                setListData(res.data.map((item) => ({
                    ...item,
                    key: item.user
                })))
            }
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        setSelectedRowKeys([]);
        getUserList();
    }, [getUserList, isRefresh])

    const blockedUser = useCallback((value) => {
        setSelectedData(value);
        setIsModalOpen(true)

    }, [])

    const List = [
        {
            title: GetWord("Username"),
            dataIndex: 'user.username',
            render: (username, record) =>
                <div className='users-BodyArea-List-UsernameArea'>
                    <img src={record.user.image} alt=''
                        className='users-BodyArea-List-UsernameArea-UserImage' />

                    <div className='users-BodyArea-List-UsernameArea-UserInformation'>
                        <p className='users-BodyArea-List-UsernameArea-UserInformation-Username'>{record.user.username}</p>
                        <p className='users-BodyArea-List-UsernameArea-UserInformation-Email'>{record.user.email}</p>
                    </div>
                </div>,
        },
        // {
        //     title: GetWord('Id'),
        //     dataIndex: ['user', 'id'],
        //     render: (text) =>
        //         <div className='users-BodyArea-List-Id'>
        //             <p className='users-BodyArea-List-Id-Icon'>#</p>
        //             <p className='users-BodyArea-List-Id-Text'>{text}</p>
        //         </div>

        // },
        {
            title: GetWord('Achievements'),
            dataIndex: ['statistics', 'total_progress'],
            render: (text) =>
                <div className='users-BodyArea-List-Achievements'>
                    <i className='icon-emoji_events users-BodyArea-List-Achievements-Icon' />
                    <p className='users-BodyArea-List-Achievements-Text'>{text}</p>
                </div>

        },
        {
            title: GetWord('XP'),
            dataIndex: ['statistics', 'total_point'],
            render: (text) =>
                <div className='users-BodyArea-List-XP'>
                    <p className='users-BodyArea-List-XP-Text'>{text}</p>
                </div>
        },
        {
            title: GetWord('Package'),
            dataIndex: ['user', 'packages'],
            render: (text) =>
                <div className='users-BodyArea-List-Package'>
                    <i className='icon-shopping_bag users-BodyArea-List-Package-Icon' />
                    <p className='users-BodyArea-List-Package-Text'>{text}</p>
                </div>
        },
        {
            title: GetWord('Join Date'),
            dataIndex: ['user', 'created_at'],
            render: (text) =>
                <div className='users-BodyArea-List-JoinDate'>
                    <p className='users-BodyArea-List-JoinDate-Text'>{moment(text).format("lll")}</p>
                </div>
        },
        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='users-BodyArea-List-ButtonArea'>
                    <button className='users-BodyArea-List-ButtonArea-BlockButton' onClick={() => blockedUser(record)}>
                        <i className='icon-dangerous users-BodyArea-List-ButtonArea-BlockButton-Icon' />
                        <p className='users-BodyArea-List-ButtonArea-BlockButton-Text'>{record.user.is_blocked ? GetWord("Remove Block") : GetWord("Block")}</p>
                    </button>
                    <button className='users-BodyArea-List-ButtonArea-EditButton' >
                        <i className='icon-edit users-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='users-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>

        },
    ];



    const blockSelected = () => {
        setIsModalOpen(true);
    };


    const onSelectChange = (newSelectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };





    return (
        <div className='users'>

            <div className='users-HeaderArea'>
                <div className='users-HeaderArea'>
                    <div className='users-HeaderArea-PageName'>{GetWord("Users List")}</div>
                </div>
                <div className='users-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search users-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord("Search for a user")} />
                    <div className='users-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => getUserList(search)}
                            className='users-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='users-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt users-HeaderArea-SearchFilter-Filter-Icon' /></p>

                        </Button>
                    </div>
                </div>
                {
                    selectedRowKeys.length > 0 ? <div className='users-HeaderArea-SelectedDelete'>
                        <p className='users-HeaderArea-SelectedDelete-Text'>{selectedRowKeys.length} {GetWord("Selected")}</p>
                        <div className='users-HeaderArea-SelectedDelete-Button'>
                            <>
                                <Button className='users-HeaderArea-SelectedDelete-Button-Style' type="primary" onClick={blockSelected}>
                                    <p className='users-HeaderArea-SelectedDelete-Button-Text'>{GetWord("Delete Selected")}</p>
                                    <div className='users-HeaderArea-SelectedDelete-Button-Icon'>
                                        <i className='icon-dangerous' />
                                    </div>
                                </Button>
                            </>
                        </div>
                    </div> : null
                }


                <Modal destroyOnClose={true} title="" open={isModalOpen} closable={false} className='users'
                    footer={null}>
                    <BlockSelected setIsYesBlockIt={setIsYesBlockIt} setIsRefresh={setIsRefresh} selectedRowKeys={selectedRowKeys} seletedData={seletedData} blockSelectedModal={setIsModalOpen} />

                </Modal>
            </div>


            <div className='users-BodyArea'>
                <div className='users-BodyArea-List'>
                    <Table
                        rowSelection={rowSelection}
                        loading={{ spinning: loading, indicator: <LoadingPage small={true} /> }}
                        columns={List}
                        dataSource={listData}
                        scroll={{ x: 'fit-content' }}
                        pagination={{
                            pageSize: 5
                        }}
                    />
                </div>

            </div>

            <Modal title="" open={isYesBlockIt} closable={false} className='users'
                footer={null}
                onCancel={() => setIsYesBlockIt(false)}
            >
                <BlockSelectedOk setIsYesBlockIt={setIsYesBlockIt} seletedData={seletedData} />
            </Modal>

        </div>
    )
}

export default Users