import { Button, Form, Input, Upload, message } from 'antd'
import '../CitiesContent.scss';
import React, { useCallback, useEffect, useState } from 'react'
import { GetWord, randomGuid } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ImgUpload } from '../../../../../../../utils/firebase';
import Service from '../../../../../../../Service';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const AddCities = ({ selectedData, setIsModalOpen, setIsUpdate }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const { lessonRoadId } = useParams();

    useEffect(() => {
        if (selectedData) {
            setImageUrl(selectedData?.image_url)
        }
    }, [selectedData])

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };




    const onFinish = (value) => {
        const service = new Service();
        value["road_id"] = parseInt(lessonRoadId);

        if (selectedData) {
            value["image_url"] = imageUrl;
            service.AdminService.updateLessonRoadCities(value, selectedData.id).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            })
        } else {
            value["image_url"] = imageUrl;
            service.AdminService.postLessonRoadCities(value).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsUpdate((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            })
        }
    }


    const handleChange = useCallback(async (info) => {

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            var responseUrl = await ImgUpload(
                info?.file?.originFileObj,
                "webapp/",
                randomGuid()
            );
            setImageUrl(responseUrl);
        }
    }, []);
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {GetWord("Upload Image")}
            </div>
        </div>
    );

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className='CitiesContent-BodyArea-AddCities-Modal'
            initialValues={selectedData}
        >
            <Form.Item name="name" className='CitiesContent-BodyArea-AddCities-Modal-CitiesComment' label={<p className='CitiesContent-BodyArea-AddCities-Modal-CitiesComment-Title'>{GetWord("City Explanation")}</p>}>
                <Input placeholder={GetWord("Enter a description")} className='CitiesContent-BodyArea-AddCities-Modal-CitiesComment' />
            </Form.Item>
            <Form.Item name="description" className='CitiesContent-BodyArea-AddCities-Modal-CitiesComment' label={<p className='CitiesContent-BodyArea-AddCities-Modal-CitiesComment-Title'>{GetWord("City Explanation")}</p>}>
                <TextArea type='textArea' placeholder={GetWord("Enter a description")} className='CitiesContent-BodyArea-AddCities-Modal-CitiesComment' style={{
                    resize: 'none',
                }} />
            </Form.Item>
            <Form.Item className='CitiesContent-BodyArea-AddCities-Modal-Image' label={<p className='CitiesContent-BodyArea-AddCities-Modal-Image-Title'>{GetWord("Image")}</p>}>
                <>
                    <Upload
                        name="cityphoto"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://httpstat.us/200"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        maxCount={1}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="cityphoto"
                                style={{
                                    width: '100%',
                                }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </>

            </Form.Item>


            <Form.Item className='CitiesContent-BodyArea-AddCities-Modal-Button'>
                <Button htmlType='submit' className='CitiesContent-BodyArea-AddCities-Modal-Button-Style'>
                    <p className='CitiesContent-BodyArea-AddCities-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save CitiesContent-BodyArea-AddCities-Modal-Button-Text' /></p>
                </Button>
            </Form.Item>
        </Form>
    )
}

export default AddCities