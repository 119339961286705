import React, { useCallback, useState } from 'react'
import '../DialogueContent/DialogueContent.scss'
import { Button, Form, Input, Space, Upload, message } from 'antd'
import { MinusCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ImgUpload } from '../../../../../utils/firebase';
import { GetWord, randomGuid } from '../../../../../utils';
import Service from '../../../../../Service';
import { toast } from 'react-toastify';

const PersonelAdd = ({ setIsPersonelModal, setIsRefleshPersonal }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = useCallback(async (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            var responseUrl = await ImgUpload(
                info?.file?.originFileObj,
                "webapp/",
                randomGuid()
            );
            setImageUrl(responseUrl);
        }
    }, []);

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {GetWord("Upload Image")}
            </div>
        </div>
    );

    const onFinish = useCallback((value) => {
        value['avatar_url'] = imageUrl;
        const service = new Service();
        service.AdminService.PostDialogPersonal(value).then((res) => {
            if (!res.error && res.data) {
                toast.success(GetWord("Added successfully."));
                setIsPersonelModal(false);
                setIsRefleshPersonal((prev) => !prev)
            }
            else {
                toast.error(res.message);
            }
        })
    }, [imageUrl, setIsPersonelModal]);

    return (
        <div>

            <Form className='DialogueContent-AddDialogue-DialogueTranslate'
                form={form}
                onFinish={onFinish}>
                <Form.Item
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Konuşmacı Eksik',
                        },
                    ]}
                >
                    <Input placeholder={GetWord("Speaker Name")} className='DialogueContent-AddDialogue-DialogueTranslate-DialogueInput' />
                </Form.Item>
                <Form.Item label={<p className='CitiesContent-BodyArea-AddCities-Modal-TrueExample-Title'>{GetWord("Avatar")}</p>}>
                    <>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://httpstat.us/200"
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </>

                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' className='DialogueContent-Button-Style'>
                        <p className='DialogueContent-AddDialogue-Button-Text'>{GetWord("Save")}<i className='icon-check_circle' style={{ fontSize: 18, marginLeft: 5 }} /></p>
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}

export default PersonelAdd