import { Button, Form, Input } from 'antd'
import '../RulesContent.scss'
import React, { useEffect, useState } from 'react'
import { GetWord } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import Service from '../../../../../../../Service'
import { toast } from 'react-toastify'

const CommentAdded = ({ selectData, setIsCommentAdded, setIsUpdate }) => {
    const [form] = Form.useForm();

    const onFinish = (value) => {
        const service = new Service();
        const entity = {
            description: value.description,
            incorrect_sentence: selectData.incorrect_sentence,
            sample_sentece: selectData.sample_sentece,
            title: selectData.title,
            road_id: selectData.road_id,
        }

        service.AdminService.updateLessonRoadRules(entity, selectData.id).then((res) => {
            if (!res.error && res.data) {
                toast.success(GetWord("Added successfully."));
                setIsCommentAdded((prev) => !prev);
                setIsUpdate((prev) => !prev);
            } else {
                toast.error(res.message)
            }
        })
    }
    useEffect(() => {
        if (selectData) {

        }
    }, [selectData])
    return (
        <div className='RulesContent-BodyArea-AddRules-Modal'>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={selectData}
            >
                <Form.Item className='RulesContent-BodyArea-AddRules-Modal-RulesComment' name="description" label={<p className='RulesContent-BodyArea-AddRules-Modal-RulesComment-Title'>{GetWord("Content Description")}</p>}>
                    <TextArea type='textArea' placeholder="Açıklama Giriniz" className='RulesContent-BodyArea-AddRules-Modal-RulesComment' style={{
                        resize: 'none',
                    }} />
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" className='RulesContent-BodyArea-AddRules-Modal-Button RulesContent-BodyArea-AddRules-Modal-Button-Style' htmlType="submit">
                        <p className='RulesContent-BodyArea-AddRules-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save RulesContent-BodyArea-AddRules-Modal-Button-Text' /></p>
                    </Button>
                </Form.Item>
            </Form>
        </div >
    )
}

export default CommentAdded