import { Button, Form, Input, Upload, message } from 'antd'
import '../CitiesContent.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { GetWord, randomGuid } from '../../../../../../../utils'
import TextArea from 'antd/es/input/TextArea'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ImgUpload } from '../../../../../../../utils/firebase'
import Service from '../../../../../../../Service'
import { toast } from 'react-toastify'

const ImageAdded = ({ selectedData, setIsModalOpen, setIsUpdate }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    useEffect(() => {
        if (selectedData) {
            setImageUrl(selectedData.image_url);
        }
    }, [])


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };


    const onFinish = useCallback((value) => {
        const service = new Service();
        const entity = {
            "description": selectedData.description,
            "image_url": imageUrl,
            "name": selectedData.name,
            "road_id": selectedData.road_id
        }
        service.AdminService.updateLessonRoadCities(entity, selectedData.id).then((res) => {
            if (!res.error && res.data) {
                toast.success(GetWord("Added successfully."));
                setIsModalOpen((prev) => !prev);
                setIsUpdate((prev) => !prev);
            } else {
                toast.error(res.message)
            }
        })
    }, [imageUrl, selectedData?.description, selectedData?.id, selectedData?.name, setIsModalOpen, setIsUpdate, selectedData?.road_id]);


    const handleChange = useCallback(async (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            var responseUrl = await ImgUpload(
                info?.file?.originFileObj,
                "webapp/",
                randomGuid()
            );
            setImageUrl(responseUrl);
        }
    }, []);

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {GetWord("Upload Image")}
            </div>
        </div>
    );

    return (
        <div className='CitiesContent-BodyArea-AddCities-Modal'>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                className='CitiesContent-BodyArea-AddCities-Modal-Image'
            >
                <Form.Item name="img" label={<p className='CitiesContent-BodyArea-AddCities-Modal-TrueExample-Title'>{GetWord("Image")}</p>}>
                    <>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://httpstat.us/200"
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </>

                </Form.Item>

                <Form.Item className='CitiesContent-BodyArea-AddCities-Modal-Button'>
                    <Button htmlType='submit' className='CitiesContent-BodyArea-AddCities-Modal-Button-Style'>
                        <p className='CitiesContent-BodyArea-AddCities-Modal-Button-Text'>{GetWord("Save")}<i className='icon-save CitiesContent-BodyArea-AddCities-Modal-Button-Text' /></p>
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ImageAdded